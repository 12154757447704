import React, {useEffect} from 'react'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider} from './core'
import {useLocation} from 'react-router-dom'

import {MenuComponent} from '../assets/ts/components'
import './style.scss';
import { KTUtil } from '../assets/ts'

const MasterLayout: React.FC<{children?: React.ReactNode}> = ({children}) => {
  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  return (
    <PageDataProvider>
      <div className="page has-sidebar">
      {!KTUtil.isMobileDevice() ? (
        <>
          <header className="page-header">
            <HeaderWrapper />
          </header>
        
          <aside className="page-aside">
            <AsideDefault />
          </aside>
        </>
        ): 
          <>
            <HeaderWrapper />
            <AsideDefault />
          </>
        }
        <main className="page-main">
            <div className="grid">
              {children}
            </div>
        </main>

        <footer className="page-footer">
          <Footer />
        </footer>
      </div>
      <ScrollTop /> 
    </PageDataProvider>
  )
}

export {MasterLayout}
