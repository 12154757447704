import React from 'react'
import {Image} from './Image'
import { Droppable } from 'react-beautiful-dnd';


export function Column(column, images) {
    return (
        <div className="taskColumn">
            <div className="d-flex flex-center my-10">
                <h2> {column.column.title}</h2>
            </div>
            <Droppable droppableId={column.column.id} direction="horizontal">
                {provider =>(
                    <div {...provider.droppableProps}
                        ref={provider.innerRef}
                        className="imagesLists"
                    >

                    <div className="column d-flex border border-gray-500 border-active active">
                        {Object.keys(column.images).map((key,index) => {
                            return(<Image key={column.images[key].id} image={column.images[key]} index={index} />);
                        })}
                        </div>
                </div>
            )}

            </Droppable>
         </div>
    )
}
