/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
//import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {MasterLayoutLobby} from '../../../_metronic/layout/MasterLayoutLobby'
import {KTUtil} from '../../../_metronic/assets/ts'
import {
  Timer,
} from '../../../_metronic/partials/'

const PetitBac: FC<{ data: string}> = ({data})=>{


  return (
    <>
        <MasterLayoutLobby>
        {/* begin::Row */}
        <div className='row gy-4 g-xl-8 d-flex flex-row-fluid'>
            {!KTUtil.isMobileDevice() && (<
            div className="container d-flex flex-column">
                <h1 className="d-flex flex-column-fluid justify-content-center mb-5" >
                    PETIT BAC
                </h1>
                <h1 className="d-flex flex-column-fluid justify-content-center mb-5" >
                    <Timer />

                </h1>
                <h1 className="d-flex flex-column-fluid justify-content-center mb-5" >
                    Lettre: {data}
                </h1>
            </div>)}

            {!KTUtil.isMobileDevice() && (
                <div className="d-flex flex-column">
                    <div className="d-flex flex-row-fluid flex-column flex-center mb-10" >
                        <h2> Pirates </h2>
                        <input type="text" className="form-control form-control-solid" placeholder="Pirates" name="Pirate"/>
                    </div>
                    <div className="d-flex flex-row-fluid flex-column flex-center mb-10" >
                        <h2> Marine </h2>
                        <input type="text" className="form-control form-control-solid" placeholder="Marine" name="Marine"/>
                    </div>
                    <div className="d-flex flex-row-fluid flex-column flex-center mb-10" >
                        <h2> Ville/Village/Île </h2>
                        <input type="text" className="form-control form-control-solid" placeholder="Ville/Village/Île" name="Ville"/>
                    </div>
                    <div className="d-flex flex-row-fluid flex-column flex-center mb-10" >
                        <h2> Fruit du démon </h2>
                        <input type="text" className="form-control form-control-solid" placeholder="Fruit du démon" name="FDD"/>
                    </div>
                </div>
                )}

            {/* begin::Aside mobile toggle */}
            {KTUtil.isMobileDevice() && (
                <div className="container d-flex flex-column">
                    <h1 className="d-flex flex-column-fluid justify-content-center mb-5" >
                        PETIT BAC
                    </h1>
                    <h1 className="d-flex flex-column-fluid justify-content-center mb-5" >
                        <Timer />
                    </h1>
                    <h1 className="d-flex flex-column-fluid justify-content-center mb-5" >
                        Lettre: {data}
                    </h1>
                </div>
            )}

            {KTUtil.isMobileDevice() && (
                <div className="d-flex flex-column">
                    <div className="d-flex flex-row-fluid flex-column flex-center mb-10" >
                        <h2> Pirates </h2>
                        <input type="text" className="form-control form-control-solid" placeholder="Pirates" name="Pirate"/>
                    </div>
                    <div className="d-flex flex-row-fluid flex-column flex-center mb-10" >
                        <h2> Marine </h2>
                        <input type="text" className="form-control form-control-solid" placeholder="Marine" name="Marine"/>
                    </div>
                    <div className="d-flex flex-row-fluid flex-column flex-center mb-10" >
                        <h2> Ville/Village/Île </h2>
                        <input type="text" className="form-control form-control-solid" placeholder="Ville/Village/Île" name="Ville"/>
                    </div>
                    <div className="d-flex flex-row-fluid flex-column flex-center mb-10" >
                        <h2> Fruit du démon </h2>
                        <input type="text" className="form-control form-control-solid" placeholder="Fruit du démon" name="FDD"/>
                    </div>
                </div>
            )}


        </div>
        {/* end::Row */}
        </MasterLayoutLobby>
    </>
  )
}

export {PetitBac}
