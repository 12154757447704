import { toast, ToastOptions } from 'react-toastify'

export const notify = (msg: string, type: string) => {

    const param: ToastOptions<{}> = {
      closeOnClick: true,
      theme: "colored",
      autoClose: 5000,
      pauseOnHover: true,
      draggable: true,
    };
    switch(type){
      case 'success':
        toast.success(msg, param);
        break;
      case 'error':
        toast.error(msg, param);
        break;
      case 'warning':
        toast.warn(msg, param);
        break;
      case 'info':
        toast.info(msg, param);
        break;
      default:
        toast(msg, param);
        break;
    }
   
  }