/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
//import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {MasterLayoutLobby} from '../../../_metronic/layout/MasterLayoutLobby'
import {KTUtil} from '../../../_metronic/assets/ts'

import {
  Timer,
} from '../../../_metronic/partials/'


const VideoQuestions: FC<{ data: string}> = ({data})=> {

  return (
    <>
        <MasterLayoutLobby>
        {/* begin::Row */}
        <div className='row gy-4 g-xl-8 d-flex flex-row-fluid'>
            {!KTUtil.isMobileDevice() && (
                <div className="container d-flex flex-column">
                    <h1 className="d-flex flex-column-fluid justify-content-center pb-3" >
                        Devinez l'opening
                    </h1>
                    <h1 className="d-flex flex-column-fluid justify-content-center pb-2" >
                        <Timer />
                    </h1>
                </div>
            )}

            {!KTUtil.isMobileDevice() && (
                <div className="d-flex flex-column">
                    <div className="d-flex flex-row-fluid flex-column flex-center mb-5" >
                        <video className="py-5" controls height="400">
                            <source src="/media/cc0-videos/flower.webm"
                                    type="video/webm"/>

                            <source src={data}
                                    type="video/mp4" />

                            Désole, votre navigateur ne supporte pas les vidéos intégréés.
                        </video>
                    <input type="text" className="form-control form-control-solid" placeholder="réponse" name="answerVideo"/>
                    </div>
                </div>
                )}

            {/* begin::Aside mobile toggle */}
            {KTUtil.isMobileDevice() && (
                <div className="container d-flex flex-column">
                    <h1 className="d-flex flex-column-fluid justify-content-center mb-3" >
                        Devinez l'opening
                    </h1>
                    <h1 className="d-flex flex-column-fluid justify-content-center mb-2" >
                        <Timer />
                    </h1>
                </div>
            )}

            {KTUtil.isMobileDevice() && (
                <div className="d-flex flex-column">
                    <div className="d-flex flex-row-fluid flex-column flex-center mb-5" >
                        <video className="py-5" controls width="320" height="240">
                            <source src="/media/cc0-videos/flower.webm"
                                    type="video/webm"/>

                            <source src={data}
                                    type="video/mp4"/>

                            Désole, votre navigateur ne supporte pas les vidéos intégréés.
                        </video>
                        <input type="text" className="form-control form-control-solid" placeholder="réponse" name="answerVideo"/>
                    </div>
                </div>
            )}


        </div>
        {/* end::Row */}
        </MasterLayoutLobby>
    </>
  )
}

export {VideoQuestions}
