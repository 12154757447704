/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {toAbsoluteUrl} from '../../_metronic/helpers'

//import {toAbsoluteUrl} from '../../../_metronic/helpers'
// import {MasterLayoutLobby} from '../../_metronic/layout/MasterLayoutLobby'
// import {KTUtil} from '../../_metronic/assets/ts'


import {CultureG, DragAndDrop, HowLong, JollyRoger, PetitBac, VideoQuestions} from '.'

const Games: FC = () => {

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const jeux = ['CultureG', 'HowLong', 'JollyRoger', 'VideoQuestions', 'PetitBac', 'Resultat'];
    const [jeu, setJeu] = useState(jeux[0]);
    const [index, setIndex] = React.useState(0);
    // const [questions,setQuestions] = useState(toAbsoluteUrl("Questions/questions.json"))
     //jeux[random].questions[random]
    React.useEffect(() => {
       const timerId = setInterval(
         () => setIndex((i) => (i + 1)), // <-- increment index
         15000
       );
       return () => clearInterval(timerId);
     }, []);
     React.useEffect(() => {
         if (index < jeux.length - 1)
            setJeu(jeux[index]); // <-- update jeu state when index updates
     }, [index, jeux]);

    return (
        <>
            {(() => {
                switch (jeu) {
                    case 'CultureG':
                        return (<CultureG data="Question de culture Générale" />)
                    case 'DragAndDrop':
                        return (<DragAndDrop />)
                    case 'HowLong':
                        return (<HowLong data= "Combien de temps a duré cette saga ?"  />)
                    case 'JollyRoger':
                        return (<JollyRoger data="/media/One Piece/Drapeaux/Baggy.png" />)
                    case 'PetitBac':
                        return (<PetitBac data="A"/>)
                    case 'VideoQuestions':
                        return (<VideoQuestions data="/media/One Piece/Videos/intro.mp4" />)
                    case 'Resultat':
                        //return ()
               }
           })()}
       </>
    )

}

export {Games}
