/* eslint-disable react-hooks/exhaustive-deps */
import {Images} from './Images'

export function DragAndDropComponent() {

  return (
      <div>
        <Images />
      </div>
  )
}
