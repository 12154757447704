import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'

import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import { LobbyContent } from '../pages/lobby/Lobby'
import { PetitBac } from '../pages/petitBac/PetitBac'
import { JollyRoger } from '../pages/jollyRoger/JollyRoger'
import { DragAndDrop } from '../pages/dragAndDrop/DragAndDrop'
import { HowLong } from '../pages/howLong/HowLong'
import { CultureG } from '../pages/cultureG/CultureG'
import { VideoQuestions } from '../pages/videoQuestions/VideoQuestions'
import { Changelog } from '../pages/changelog/Changelog'
import { Contact } from '../pages/contact/Contact'
import { Games } from '../pages/Games'

import {MenuTestPage} from '../pages/MenuTestPage'
/*import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {MasterLayoutLobby} from '../../_metronic/layout/MasterLayoutLobby'
import {MasterInit} from '../../_metronic/layout/MasterInit'*/

import {Twitter} from '../externalLinks/Twitter'
import {Tiktok} from '../externalLinks/Tiktok'
import {Discord} from '../externalLinks/Discord'
import {Twitch} from '../externalLinks/Twitch'

export function PrivateRoutes() {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route exact path="/">
            <DashboardWrapper/>
        </Route>
        
        <Route exact path='/lobby/:id' component={LobbyContent} />

        {/* <Redirect from={`/invitation/:id`} to={`/lobby/:id`} /> */}

        <Route exact path='/petitBac' component={PetitBac} />
        <Route exact path="/howLong" component={HowLong} />
        <Route exact path="/cultureG" component={CultureG} />

        <Route exact path='/jollyRoger' component={JollyRoger} />

        <Route exact path='/dragAndDrop' component={DragAndDrop} />

        <Route exact path="/videoQuestions" component={VideoQuestions} />
        <Route exact path='/game' component={Games} />

        <Route exact path='/twitter' component={Twitter} />
        <Route exact path='/tiktok' component={Tiktok} />
        <Route exact path='/discord' component={Discord} />
        <Route exact path='/twitch' component={Twitch} />
        <Route exact path='/builder' component={BuilderPageWrapper} />
        <Route exact path='/testPage' component={MenuTestPage} />

        <Route exact path='/changelog' component={Changelog} />
        <Route exact path='/contact' component={Contact} />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
