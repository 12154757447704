import {toAbsoluteUrl} from '../../../../helpers'
const initialData = {
    images:{
        "images-1":{
            "image-akainu" : {id: "image-akainu", src:toAbsoluteUrl("/media/One Piece/DragAndDrop/Akainu.png"), name:"Akainu"},
            "image-alabasta" : {id: "image-alabasta", src:toAbsoluteUrl("/media/One Piece/DragAndDrop/alabasta.png"), name:"alabasta"},
            "image-aokiji" : {id: "image-aokiji", src:toAbsoluteUrl("/media/One Piece/DragAndDrop/Aokiji.png"), name:"Aokiji"},
            "image-ace" : {id: "image-ace", src:toAbsoluteUrl("/media/One Piece/DragAndDrop/ace.png"), name:"Ace" },
        },
        "images-2":{
            "image-akainu" : {id: "image-akainu", src:toAbsoluteUrl("/media/One Piece/DragAndDrop/Akainu.png"), name:"Akainu"},
            "image-alabasta" : {id: "image-alabasta", src:toAbsoluteUrl("/media/One Piece/DragAndDrop/alabasta.png"), name:"alabasta"},
            "image-aokiji" : {id: "image-aokiji", src:toAbsoluteUrl("/media/One Piece/DragAndDrop/Aokiji.png"), name:"Aokiji"},
            "image-ace" : {id: "image-ace", src:toAbsoluteUrl("/media/One Piece/DragAndDrop/ace.png"), name:"Ace" },
        },

    },
    imagesKey: ["images-1", "images-2"],
    columns: {
        "column-1":{
            id: "column-1",
            title: "Images à trier",
            taskIds: ["image-akainu", "image-alabasta", "image-aokiji", "image-ace"],
        }
    },

    columnOrder: ["column-1"],
}

export {initialData}
