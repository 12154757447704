import { useEffect } from "react";

function Twitch() {

  useEffect(() => {
    window.location.href = "https://www.twitch.tv/shand0ra";
  }, []);

  return (
    null
  );
}


export {Twitch}
