/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
//import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {MasterLayoutLobby} from '../../../_metronic/layout/MasterLayoutLobby'
import {KTUtil} from '../../../_metronic/assets/ts'
import {
  Timer,
} from '../../../_metronic/partials/'


const CultureG: FC<{ data: string}> = ({data})=> {

  return (
    <>
        <MasterLayoutLobby>
        {/* begin::Row */}
        <div className='row mt-5 gy-4 g-xl-8 d-flex flex-row-fluid'>
            {!KTUtil.isMobileDevice() && (<
            div className="container d-flex flex-column">
                <h1 className="d-flex flex-column-fluid justify-content-center py-5" >
                    COMBIEN DE TEMPS
                </h1>
                <h1 className="d-flex flex-column-fluid justify-content-center py-5" >
                    <Timer />
                </h1>
            </div>)}

            {!KTUtil.isMobileDevice() && (
                <div className="d-flex flex-column">
                    <div className="d-flex flex-row-fluid flex-column flex-center py-20" >
                        <h2 className="py-10"> {data} </h2>
                        <input type="text" className="form-control form-control-solid" placeholder="réponse" name="answerHowLong"/>
                    </div>
                </div>
                )}

            {/* begin::Aside mobile toggle */}
            {KTUtil.isMobileDevice() && (
                <div className="container d-flex flex-column">
                    <h1 className="d-flex flex-column-fluid justify-content-center py-5" >
                        Culture Générale: One Piece
                    </h1>
                    <h1 className="d-flex flex-column-fluid justify-content-center py-5" >
                        <Timer />
                    </h1>
                </div>
            )}

            {KTUtil.isMobileDevice() && (
                <div className="d-flex flex-column">
                    <div className="d-flex flex-row-fluid flex-column flex-center py-20" >
                        <h2 className="py-10">  Inserer ici une question de culture G ? </h2>
                        <input type="text" className="form-control form-control-solid" placeholder="réponse" name="answerHowLong"/>
                    </div>
                </div>
            )}


        </div>
        {/* end::Row */}
        </MasterLayoutLobby>
    </>
  )
}

export {CultureG}
