import React from 'react'
//import ReactDOM from "react-dom";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

const Timer = () => (
  <CountdownCircleTimer
    isPlaying
    duration={15}
    colors={['#004777', '#F7B801', '#A30000', '#A30000']}
    colorsTime={[15, 10, 5, 0]}
    size={120}
    onComplete={() => {
     // do your stuff here

     return { shouldRepeat: true, delay: 1.5 } // repeat animation in 1.5 seconds
   }}
  >
    {({ remainingTime }) => remainingTime}
  </CountdownCircleTimer>
)

export {Timer};
