
import {Component} from 'react'

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


interface MesProps {
  name: String;
  date: String;
  version: String;
  news: Array<String>;
  updates: Array<String>;
  fixes: Array<String>;
  nomExpended: String;
}

class CreateAccordion extends Component<MesProps> {

  constructor(props: any) {
     super(props)
     this.state = {
        expanded: false,
        name: props.name,
        date: props.date ,
        version: props.version, 
        news: props.news,
        updates: props.updates,
        fixes: props.fixes,
     }
     this.handleChange = this.handleChange.bind(this);
 }
    
  handleChange = () => {
      const expended = false;
      this.setState({expanded: expended});
  };

  render() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {name, date, version, news, updates, fixes, nomExpended} = this.props
    const liNews = news.map((uneNew) => <li className="text-white">{uneNew}</li>)
    const liUpdates = updates.map((uneUpdate) => <li className="text-white">{uneUpdate}</li>)
    const liFixes = fixes.map((unFixe) => <li className="text-white">{unFixe}</li>)

    return(<Accordion sx={{background: '#1B2631',}}  onChange={this.handleChange}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography sx={{ width: '33%', flexShrink: 0, fontWeight:'bold',color:"white", }}>{name} - {date}</Typography>
              <Typography sx={{ color: 'gray' }}>
                version {version}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {news.length > 0 && (
                    <>
                        <span className="fw-bolder d-block mb-2 text-white">News:</span>
                        <ul>
                            {liNews}
                        </ul>
                    </>
                )}

              {updates.length > 0 && (
                    <>
                        <span className="fw-bolder d-block mb-2 text-white">Updates:</span>
                        <ul>
                            {liUpdates}
                        </ul>
                    </>
                )}

              {fixes.length > 0 && (
                    <>
                        <span className="fw-bolder d-block mb-2 text-white">Fixes:</span>
                        <ul>
                            {liFixes}
                        </ul>
                    </>
                )}
              </Typography>
            </AccordionDetails>
          </Accordion>
      ) 
    }
}

export {CreateAccordion};