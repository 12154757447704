import Button from '@mui/material/Button';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react'

function FormHome({props}){

    const [pseudo, setPseudo, handleJoinParty, handleCreateParty] = props;
    const [isDisabled, setIsDisabled] = useState(true);
    
    const handleChange = (e) => {
        const copieIsDisabled = e.target.value.length > 3 ? false : true;
        const copiePseudo = e.target.value;
        setIsDisabled(copieIsDisabled)
        setPseudo(copiePseudo)
    }
    useEffect(()=>{
        // console.log('pseudo lenght > 3', pseudo.length > 3, 'pseudo:', pseudo);
        setIsDisabled(pseudo.length > 3 ? false: true);
    }, [pseudo]);

    return (
        <>
            <form>
                <div className='d-flex flex-center flex-wrap mb-5'>
                    <input type="text" className="form-control form-control-solid mb-4" onChange={handleChange} value={pseudo} placeholder="Entrez votre pseudo..." name="pseudo" autoComplete="off" />
                </div>
                <div className="d-flex flex-column">
                    <Button 
                    onClick={handleJoinParty}
                    variant="contained" 
                    disabled={isDisabled}
                    size="large"
                    sx={{marginBottom: 2}}> 
                    Rejoindre une partie.
                    </Button>

                    <Button 
                    onClick={handleCreateParty} 
                    // onClick={notify}
                    variant="contained" 
                    color="success"
                    size="large"
                    disabled={isDisabled}
                    sx={{}}>
                        Créer une partie privée.
                    </Button>
                </div>
            </form>
        </>
    );
}
export {FormHome};
