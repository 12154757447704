import React from 'react'
import { toAbsoluteUrl} from '../../../helpers'
import { Draggable } from 'react-beautiful-dnd';
import {KTUtil} from '../../../assets/ts'

export function Image(image, index) {

    console.log(image)
  return (
      <div>
        <Draggable draggableId={image.image.id} index={image.index}>
        {provider =>(
            <div {...provider.draggableProps}
                {...provider.dragHandleProps}
                ref={provider.innerRef}
                className="imageDrag"
            >
                {!KTUtil.isMobileDevice() && (
                      <div className="taskItem d-flex flex-column bg-secondary card-rounded flex-center mx-5 my-2">
                            <span> {image.image.name}</span>
                            <img className="h-150px w-150px card-rounded shadow mw-150" src={toAbsoluteUrl(image.image.src)} alt="" />
                      </div>)}
                {KTUtil.isMobileDevice() && (
                    <div className="taskItem d-flex flex-column bg-secondary card-rounded flex-center mx-5 my-2">
                          <span> {image.image.name}</span>
                          <img className="h-60px w-60px card-rounded shadow mw-150" src={toAbsoluteUrl(image.image.src)} alt="" />
                    </div>)}
             </div>
          )}
        </Draggable>
    </div>
  )
}
