/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
//import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {MasterLayoutLobby} from '../../../_metronic/layout/MasterLayoutLobby'
import {KTUtil} from '../../../_metronic/assets/ts'
import {
  Timer,
} from '../../../_metronic/partials/'

const HowLong: FC<{ data: string}> = ({data})=> {

  return (
    <>
        <MasterLayoutLobby>
        {/* begin::Row */}
        <div className='row mt-5 gy-4 g-xl-8 d-flex flex-row-fluid'>
            {!KTUtil.isMobileDevice() && (
            <div className="container d-flex flex-column">
                <h1 className="d-flex flex-column-fluid justify-content-center py-5" >
                    COMBIEN DE TEMPS
                </h1>
                <h1 className="d-flex flex-column-fluid justify-content-center py-5" >
                    <Timer />
                </h1>
            </div>)}

            {!KTUtil.isMobileDevice() && (
                <div className="d-flex flex-column">
                    <div className="d-flex flex-row-fluid flex-column flex-center py-20" >
                        <h2 className="py-10"> {data} </h2>

                        <h1 className="">Question</h1>
                        <p> Réponse </p>
                        <div className="d-flex justify-content-between w-100" >
                            <span className="">Nom du joueur X</span>
                            <input type="text" className="form-control form-control-solid" placeholder="reponse du joueur X" value="reponse du joueur X" name="answerHowLong"/>
                            <button className="btn btn-success btn-lg btn-block text-nowrap">
                                <span>valide</span>
                            </button>
                        </div>

                        <hr className="solid"/>
                        <div className="border-top my-3"></div>
                        <div className="d-flex justify-content-between w-100" >
                            <span className="">Nom du joueur Y</span>
                            <input type="text" className="form-control form-control-solid" placeholder="reponse du joueur Y" value="reponse du joueur Y" name="answerHowLong"/>
                            <button className="btn btn-danger btn-lg btn-block text-nowrap pl-2">
                                <span>Non valide</span>
                            </button>
                        </div>

                        <hr className="solid" />
                        <div className="border-top my-3"></div>
                        <div className="d-flex justify-content-between w-100" >
                            <span className="">Nom du joueur Z</span>
                            <input type="text" className="form-control form-control-solid" placeholder="reponse du joueur Z" value="reponse du joueur Z" name="answerHowLong"/>
                            <button className="btn btn-danger btn-lg btn-block text-nowrap">
                                <span>Non valide</span>
                            </button>
                        </div>

                        <hr className="solid" />
                        <button className="btn btn-secondary btn-lg btn-block text-nowrap">
                            <span>Question suivante</span>
                        </button>
                    </div>
                </div>

                )}
            </div>
            </MasterLayoutLobby>
        </>
    )
}

export {HowLong}
