/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {MasterLayoutLobby} from '../../../_metronic/layout/MasterLayoutLobby'
import {KTUtil} from '../../../_metronic/assets/ts'


const Contact = () => {

const texteContact = "Contactez moi en cas de problème sur dev@shandora.games ou directement sur Discord";
  return (
    <>
        <MasterLayoutLobby>
            {/* begin::Row */}
            <div className='column mt-15 d-flex flex-column'>
                {!KTUtil.isMobileDevice() ? 
                (<div className="container d-flex">
                    <h3 className="d-flex flex-column-fluid justify-content-center" >
                        {texteContact}
                    </h3>
                </div>
                ) : (
                    <div className="container d-flex">
                        <h3 className="d-flex flex-column justify-content-center" >
                            {texteContact}
                        </h3>
                    </div>
                )}
            </div>
            {/* end::Row */}
        </MasterLayoutLobby>
    </>
  )
}

export {Contact}
