import {FormHome, notify} from '../../../layout/components'
import { useContext, useEffect, useState } from 'react'
import { WebSocketContext } from '../../../contexts/WebsocketContext'
import { ToastContainer } from 'react-toastify'
import { InfoJoueurContext } from '../../../contexts/PlayerContext'
import Slider from '../../../layout/components/SliderComponent/Slider'
import { useHistory } from 'react-router-dom'


/**
 * Création d'un UUID unique pour la partie
 */
export const uuid = () =>{
  let S4 = () =>{
    return (((1 + Math.random())* 0x10000) | 0).toString(16).substring(1);
  }
  return(S4() + '-' + S4());
}

const PlayerLobbyCard = () => {
  
  const socket = useContext(WebSocketContext);
  const infoJoueur = useContext(InfoJoueurContext);
  const uuidPartie = uuid();
  const [pseudo, setPseudo] = useState("");
  const [avatar, setAvatar] = useState("");
  const joinPartyUuid = "";
  const history = useHistory();
  

  useEffect(()=>{
    /* si le jeu a déjà une session de jeu en cours plsieurs onglets */
    const sessionID = localStorage.getItem("sessionID");
    const pseudoCookie = localStorage.getItem('username') !== null ? localStorage.getItem('username') : "";
    console.log('sessionID front', sessionID);
    console.log(socket);
    if (sessionID) {
        socket.auth = { sessionID };
        if(pseudoCookie !== "undefined"){
          // console.log('pseudo undefined', pseudoCookie);
          socket.auth = {pseudo: String(pseudoCookie)};
          setPseudo(String(pseudoCookie));
        }
        
        socket.connect();
    }

    
    
    socket.on('session', ({sessionID, userID, pseudo} ) => {
        // attach the session ID to the next reconnection attempts
        socket.auth = { sessionID, userID, pseudo};
        // save the ID of the user
        

         // store it in the localStorage
         localStorage.setItem('sessionID', sessionID);
         localStorage.setItem('username', pseudo);
    });

    socket.on('connect', () =>{
        console.log(`Connecté {}`);
    });

    socket.on('createNewParty', ({uuidCreatePartie}) =>{
      console.log('pseudo: ', infoJoueur.pseudo, '...');
      socket.auth = {pseudo: infoJoueur.pseudo, ...socket.auth};
      localStorage.setItem('username', infoJoueur.pseudo);
      history.push({
        pathname: `/lobby/${uuidCreatePartie}`
      })
      // history.push({
        //     pathname: path('login'),
        //     search: '?pwdreseted=true',
        //   });
    });

    socket.on('joinParty', ({uuidJoinPartie})=>{
        history.push({
          pathname: `/lobby/${uuidJoinPartie}`
        })
    });

    return () =>{
        socket.off('connect');
        socket.off('session');
        socket.off('createNewParty');
        socket.off('joinParty');
    }
  }, [socket]);


  const handleCreateParty = async (e: { preventDefault: () => void }) =>{
    e.preventDefault();
    if([uuidPartie, avatar, pseudo].some((element) => element === "")){
      notify("Erreur de création de partie, réactualisez la page","warning")
      return;
    }
    infoJoueur.imageJoueur = avatar;
    infoJoueur.pseudo = pseudo;
    infoJoueur.isAdmin = true;
    console.log(socket);
    socket.emit('createNewParty',{
        "partieID": uuidPartie,
        // 'userID': socket.auth.userID,
        "pseudo": pseudo,
        "image": avatar,
    });
  }

  const handleJoinParty = (e: { preventDefault: () => void }) =>{
    e.preventDefault();

    if([joinPartyUuid, avatar, pseudo].some((element) => element === "")){
      notify("Rejoignez une partie à partir d'une invitation","error")
      return;
    }
    infoJoueur.imageJoueur = avatar;
    infoJoueur.pseudo = pseudo;
    infoJoueur.isAdmin = false;
    socket.emit('joinParty',{
      "partieID": uuidPartie,
      "pseudo": pseudo,
      "image": avatar,
    });
  }
  
  return (
    <>
      <div className='card'>
        <div className='card-body p-9'> {/*d-flex flex-column*/}
          <div className='mb-5'>
              <Slider setAvatar={setAvatar}/>
          </div>
          <FormHome props={[pseudo, setPseudo, handleJoinParty, handleCreateParty]} />
        </div>
      </div>
      <ToastContainer newestOnTop/>
    </>
    
  )
}
export {PlayerLobbyCard}
