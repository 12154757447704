import { createContext } from "react";

export const infoJoueur = {
    pseudo: '',
    imageJoueur: '',
    isAdmin: false,
    // updatePseudo : (pseudo: any) => {}, //React.Dispatch<React.SetStateAction<string>>
    // updateImageJoueur: (imageURL: any) =>{},
    // updateIsAdmin: (isAdmin: any) =>{},
}
export const InfoJoueurContext = createContext(infoJoueur);
export const InfoJoueurProvider = InfoJoueurContext.Provider;