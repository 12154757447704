import {FC, useState} from 'react'
import {
  Card2,
} from '../../../_metronic/partials/'

import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {MasterLayoutLobby} from '../../../_metronic/layout/MasterLayoutLobby'
import {KTUtil} from '../../../_metronic/assets/ts'
import { useHistory, useLocation } from 'react-router-dom';

const LobbyContent: FC = () => {

        /*const id = match.params.id; // CA MARCHE
        const pseudo = match.params.pseudo; // CA MARCHE
        console.log(pseudo)*/
        const [pseudo, ] = useState( sessionStorage.getItem('pseudo'));
        const [isAdmin, ] = useState( sessionStorage.getItem('admin'));
        // const [image, setImage] = useState( sessionStorage.getItem('image'));
        const [isCopied, setIsCopied] = useState(false);
        const [lien] = useState(window.location.href)
        const [buttonStyle, setButtonStyle] = useState('btn btn-secondary btn-lg btn-block text-nowrap')
        
        const history = useHistory();
        const location = useLocation();
        const params = new URLSearchParams(location.search);
        // history.push({
        //     pathname: path('login'),
        //     search: '?pwdreseted=true',
        //   });
        console.log(isAdmin)

        if(pseudo !== undefined){
            console.log(pseudo)
        }
        const styleMobileSpan = {
            maxWidth: 80,
            minWidth: 80,
            display: 'inline-block',
            justifyContent: 'center'

        };

       const classNameDivMobile = {
           className:"d-flex flex-column bg-secondary card-rounded flex-center mx-2 mw-200"
       };
       const classNameImgMobile = {
           className:"h-80px w-80 card-rounded shadow mw-100"
       };

       const classNameImg = {
           className: "h-150px w-150px card-rounded shadow mw-150"
       }
       const classNameDiv = {
           className: "d-flex flex-column bg-secondary card-rounded flex-center mx-2"
       }
       const classNameSpan = {
           className:"d-flex justify-content-center w-150px text-truncate"
       }

    //    const styleSpan = {
    //        maxWidth: 80,
    //        display: 'inline-block',
    //        justifyContent: 'start'

    //    };
    // This is the function we wrote earlier
    async function copyTextToClipboard(text: any) {
      if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text);
      } else {
        return document.execCommand('copy', true, text);
      }
    }

    // onClick handler function for the copy button
     const handleCopyClick = () => {
       // Asynchronously call copyTextToClipboard
       copyTextToClipboard(lien)
         .then(() => {
           // If successful, update the isCopied state value
           setIsCopied(true);
           setButtonStyle('btn btn-success btn-lg btn-block text-nowrap')
           setTimeout(() => {
             setIsCopied(false);
             setButtonStyle('btn btn-secondary btn-lg btn-block text-nowrap')
           }, 1500);
         })
         .catch((err) => {
           console.log(err);
         });
     }


  return (
    <>
        <MasterLayoutLobby>
        {/* begin::Row */}
        <div className='row gy-4 g-xl-8 d-flex flex-row-fluid'>
            {!KTUtil.isMobileDevice() && (<div className="container d-flex flex-row-auto">
                { /*// <h1 className="d-flex flex-column-fluid justify-content-start" >
                //
                // </h1>
                // <h1 className="d-flex flex-column-fluid justify-content-start">
                //     JOUEURS
                // </h1> */}
            </div>)}

            {!KTUtil.isMobileDevice() ? (
                <div className="d-flex flex-column-auto">
                    <div className="d-flex flex-row-fluid flex-center w-150px" >
                    {isAdmin && (
                        <Card2 />
                    )}
                    {!isAdmin && (
                        <div> non admin </div>
                    )}
                    </div>
                    <div className="d-flex flex-row-fluid flex-column flex-center">
                        <div className="d-flex flex-row justify-content-around my-2 px-2">
                            <div {...classNameDiv}>
                                <img {...classNameImg} src={toAbsoluteUrl("/media/avatars/screenshot_1.png")} alt="" />
                                <span {...classNameSpan}> Admin (Joueur 1)</span>
                            </div>
                            <div {...classNameDiv}>
                                <img {...classNameImg} src={toAbsoluteUrl("/media/avatars/screenshot_2.png")} alt="" />
                                <span {...classNameSpan}> Joueur 2 </span>
                            </div>
                            <div {...classNameDiv}>
                                <img {...classNameImg} src={toAbsoluteUrl("/media/avatars/screenshot_3.png")} alt="" />
                                <span {...classNameSpan}> Joueur 3 </span>
                            </div>
                            <div {...classNameDiv}>
                                <img {...classNameImg} src={toAbsoluteUrl("/media/avatars/screenshot_4.png")} alt="" />
                                <span {...classNameSpan}> Joueur 4 </span>
                            </div>
                        </div>
                        <div className="d-flex flex-column-fluid flex-row my-2">
                            <div {...classNameDiv}>
                                <img {...classNameImg} src={toAbsoluteUrl("/media/avatars/screenshot_5.png")} alt="" />
                                <span {...classNameSpan}> Joueur 1 </span>
                            </div>
                            <div {...classNameDiv}>
                                <img {...classNameImg} src={toAbsoluteUrl("/media/avatars/screenshot_6.png")} alt="" />
                                <span {...classNameSpan}> Joueur 2 </span>
                            </div>
                            <div {...classNameDiv}>
                                <img {...classNameImg} src={toAbsoluteUrl("/media/avatars/screenshot_7.png")} alt="" />
                                <span {...classNameSpan}> Joueur 3 </span>
                            </div>
                            <div {...classNameDiv}>
                                <img {...classNameImg} src={toAbsoluteUrl("/media/avatars/screenshot_8.png")} alt="" />
                                <span {...classNameSpan}> Joueur 4 </span>
                            </div>
                        </div>
                        <div className="d-flex flex-column-fluid flex-row my-2">
                            <div {...classNameDiv}>
                                <img {...classNameImg} src={toAbsoluteUrl("/media/avatars/screenshot_1.png")} alt="" />
                                <span {...classNameSpan}>Champion d'afrique</span>
                            </div>
                            <div {...classNameDiv}>
                                <img {...classNameImg} src={toAbsoluteUrl("/media/avatars/screenshot_2.png")} alt="" />
                                <span {...classNameSpan}> Joueur 2 </span>
                            </div>
                            <div {...classNameDiv}>
                                <img {...classNameImg} src={toAbsoluteUrl("/media/avatars/screenshot_3.png")} alt="" />
                                <span {...classNameSpan}> Joueur 3 </span>
                            </div>
                            <div {...classNameDiv}>
                                <img {...classNameImg} src={toAbsoluteUrl("/media/avatars/screenshot_4.png")} alt="" />
                                <span {...classNameSpan} >Joueur 4  </span>
                            </div>
                        </div>
                    </div>
                </div>) :            
                ( 
                <>
                {/* begin::Aside mobile toggle */}
                <div className="container d-flex flex-column">
                    <h1 className="d-flex flex-column-fluid justify-content-center" >

                    </h1>
                    <div className="d-flex flex-column-fluid flex-center" >
                        {isAdmin && (
                            <Card2 />
                        )}
                    </div>
                </div>            
                <div className="d-flex flex-column">

                    <h1 className="d-flex flex-column-fluid justify-content-center">
                        JOUEURS
                    </h1>
                    <div className="d-flex flex-row-fluid flex-column flex-center">
                        <div className="d-flex flex-row justify-content-around my-2">
                            <div {...classNameDivMobile}>
                                <img {...classNameImgMobile} src={toAbsoluteUrl("/media/avatars/screenshot_1.png")} alt="" />
                                <span className="d-inline-block text-truncate" style={styleMobileSpan}> Admin (Joueur 1)</span>
                            </div>
                            <div {...classNameDivMobile}>
                                <img {...classNameImgMobile} src={toAbsoluteUrl("/media/avatars/screenshot_2.png")} alt="" />
                                <span className="d-inline-block text-truncate" style={styleMobileSpan}> Joueur 2 </span>
                            </div>
                            <div {...classNameDivMobile}>
                                <img {...classNameImgMobile} src={toAbsoluteUrl("/media/avatars/screenshot_3.png")} alt="" />
                                <span className="d-inline-block text-truncate" style={styleMobileSpan}> Joueur 3 </span>
                            </div>
                            <div {...classNameDivMobile}>
                                <img {...classNameImgMobile} src={toAbsoluteUrl("/media/avatars/screenshot_4.png")} alt="" />
                                <span className="d-inline-block text-truncate" style={styleMobileSpan}> Joueur 4 </span>
                            </div>
                        </div>
                        <div className="d-flex flex-column-fluid flex-row my-2">
                            <div {...classNameDivMobile}>
                                <img {...classNameImgMobile} src={toAbsoluteUrl("/media/avatars/screenshot_5.png")} alt="" />
                                <span className="d-inline-block text-truncate" style={styleMobileSpan}> Joueur 1 </span>
                            </div>
                            <div {...classNameDivMobile}>
                                <img {...classNameImgMobile} src={toAbsoluteUrl("/media/avatars/screenshot_6.png")} alt="" />
                                <span className="d-inline-block text-truncate" style={styleMobileSpan}> Joueur 2 </span>
                            </div>
                            <div {...classNameDivMobile}>
                                <img {...classNameImgMobile} src={toAbsoluteUrl("/media/avatars/screenshot_7.png")} alt="" />
                                <span className="d-inline-block text-truncate" style={styleMobileSpan} > Joueur 3 </span>
                            </div>
                            <div {...classNameDivMobile}>
                                <img {...classNameImgMobile} src={toAbsoluteUrl("/media/avatars/screenshot_8.png")} alt="" />
                                <span className="d-inline-block text-truncate" style={styleMobileSpan}> Joueur 4 </span>
                            </div>
                        </div>
                        <div className="d-flex flex-column-fluid flex-row my-2">
                            <div {...classNameDivMobile}>
                                <img {...classNameImgMobile} src={toAbsoluteUrl("/media/avatars/screenshot_1.png")} alt="" />
                                <div className="text-truncate" style={styleMobileSpan}> Joueur 1 </div>
                            </div>
                            <div {...classNameDivMobile}>
                                <img {...classNameImgMobile} src={toAbsoluteUrl("/media/avatars/screenshot_2.png")} alt="" />
                                <span className=" text-truncate justify-content-center" style={styleMobileSpan}> Joueur 2</span>
                            </div>
                            <div {...classNameDivMobile}>
                                <img {...classNameImgMobile} src={toAbsoluteUrl("/media/avatars/screenshot_3.png")} alt="" />
                                <span className="text-truncate" style={styleMobileSpan}> Joueur 3 aaaaaaaaaa</span>
                            </div>
                            <div {...classNameDivMobile}>
                                <img {...classNameImgMobile} src={toAbsoluteUrl("/media/avatars/screenshot_4.png")} alt="" />
                                <span className="text-truncate" style={styleMobileSpan} > Joueur 4 aaaaaas </span>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            )}


            <div className="d-flex align-items-center flex-center" >
                <input type="text" className="form-control form-control-solid" value={lien || ""} placeholder={lien} name="pseudo" defaultChecked/>
                <button className={buttonStyle} onClick={handleCopyClick}>
                    <span>{isCopied ? 'Copié !' : 'Partager la partie'}</span>
                </button>
            </div>

        </div>
        {/* end::Row */}
        </MasterLayoutLobby>
    </>
  )
}

export {LobbyContent}
