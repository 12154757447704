import React, { FC, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import "./Slider.css"

type Props = {
    setAvatar: ((arg0: string) => void)
  }

const Slider: FC<Props> =  ( {setAvatar}) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const images = [
        "/media/avatars/screenshot_1.png",
        "/media/avatars/screenshot_2.png",
        "/media/avatars/screenshot_3.png",
        "/media/avatars/screenshot_4.png",
        "/media/avatars/screenshot_5.png",
        "/media/avatars/screenshot_6.png",
        "/media/avatars/screenshot_7.png",
    ];
    const onClick = (index: any) => {
        setAvatar(images[index]);
    };
    useEffect(() => {
        setAvatar(images[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <Carousel
            showStatus={false}
            showArrows={false}
            showThumbs={false}
            useKeyboardArrows={true} 
            dynamicHeight={false}
            onChange={onClick}
            showIndicators={true}>
            {images.map((image, index) => (
                <div key={index}>
                    <img src={image} alt={"avatar n°" + index} />
                </div>
            ))}
        </Carousel>
    );
}

export default Slider;