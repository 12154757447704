/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {MasterLayoutLobby} from '../../../_metronic/layout/MasterLayoutLobby'
import {CreateAccordion} from './CreateAccordion'


function Changelog() {
  return (
      <MasterLayoutLobby>
        <div className="container d-flex flex-column my-10">
          <CreateAccordion name={"Davy Back Fight"} date={"11/2022"} version={"1.0"} news={['premiere version']} updates={[]} fixes={[]} nomExpended={'label1'}/>        
        </div>
    </MasterLayoutLobby>
  );
}

export {Changelog}
