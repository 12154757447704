import { useEffect } from "react";

function Twitter() {
      useEffect(() => {
        window.location.href = "https://twitter.com/Shand0ra_";
      }, []);

      return (
        null
      );
}


export {Twitter}
