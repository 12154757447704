import React, {Suspense, useState} from 'react'
import {BrowserRouter} from 'react-router-dom'
import { InfoJoueurProvider } from '../_metronic/contexts/PlayerContext'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'


import {Routes} from './routing/Routes'

type Props = {
  basename: string
}

const App: React.FC<Props> = ({basename}) => {
  
  const pseudo = '';
  const imageJoueur = '';
  const isAdmin = false;

  const contextValue = {
    pseudo,
    imageJoueur,
    isAdmin,
    // updatePseudo : setPseudo,
    // updateImageJoueur: setImageJoueur,
    // updateIsAdmin: setIsAdmin
  }

  return (
    <InfoJoueurProvider value={contextValue}>
    {/* <Suspense fallback={<LayoutSplashScreen />}> */}
      <BrowserRouter basename={basename}>
          <I18nProvider>
            <LayoutProvider>
                <Routes />
            </LayoutProvider>
          </I18nProvider>
      </BrowserRouter>
    {/* </Suspense> */}
    </InfoJoueurProvider>
  )
}

export {App}
