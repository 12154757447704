/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'

import {initialData} from '../../../layout/components/DragAndDrop/config/datas'


const Card2: FC = () => {
    const [tri] = useState(initialData)

    return (

        <div className="card border border-2 border-gray-300 border-hover hover-scroll" >
          <div className='card-header border-0 pt-9'>
            <div className='card-title m-0'>
              <div className='align-center'> {/* bg-light */}
                PARAMETRES
              </div>
            </div>

            {/*<div className='h-4px w-100 bg-light mb-5' data-bs-toggle='tooltip'title='This project completed'></div> */}

          </div>

          <div className='card-body p-9'>
            <div className='fs-3 fw-bolder text-dark'></div>

            <p className='text-gray-400 fw-bold fs-5 mt-1 mb-7'>Choissisez les paramètres de la partie</p>

            <div id="checkBoxes" className="row  form-check form-check-custom form-check-solid h-300px">
                <div className="flex-row py-2">
                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked/>
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                        Petit bac (6)
                    </label>
                </div>
                <div className="flex-row py-2">
                    <input className="form-check-input" type="checkbox" value="" id="trouveDrapeau" checked/>
                    <label className="form-check-label" htmlFor="trouveDrapeau">
                        Trouve le drapeau (2)
                    </label>
                </div>
                <div className="flex-row py-2">
                    <input className="form-check-input" type="checkbox" value="" id="cultureG" checked/>
                    <label className="form-check-label" htmlFor="cultureG">
                         culture générale: One Piece (3)
                    </label>
                </div>
                <div className="flex-row py-2">
                    <input className="form-check-input" type="checkbox" value="" id="combienDeTemps" checked/>
                    <label className="form-check-label" htmlFor="combienDeTemps">
                        combien de temps (5)
                    </label>
                </div>
                <div className="flex-row py-2">
                    <input className="form-check-input" type="checkbox" value="" id="opening" checked/>
                    <label className="form-check-label" htmlFor="opening">
                        opening (6)
                    </label>
                </div>
                <div className="flex-row py-2">
                    <input className="form-check-input" type="checkbox" value="" id="trier" disabled/>
                    <label className="form-check-label" htmlFor="trier">
                        Trier ces images ({tri.imagesKey.length})
                    </label>
                </div>
                <div className="flex-row py-2">
                    <input className="form-check-input" type="checkbox" value="" id="typeQuestion7" disabled/>
                    <label className="form-check-label" htmlFor="typeQuestion7">
                        À venir
                    </label>
                </div>
                <div className="flex-row py-2">
                    <input className="form-check-input" type="checkbox" value="" id="typeQuestion8" disabled/>
                    <label className="form-check-label" htmlFor="typeQuestion8">
                        À venir
                    </label>
                </div>
                <div className="flex-row py-2">
                    <input className="form-check-input" type="checkbox" value="" id="typeQuestion9" disabled/>
                    <label className="form-check-label" htmlFor="typeQuestion9">
                        À venir
                    </label>
                </div>
                <div className="flex-row py-2">
                    <input className="form-check-input" type="checkbox" value="" id="typeQuestion10" disabled/>
                    <label className="form-check-label" htmlFor="typeQuestion10">
                        À venir
                    </label>
                </div>
            </div>
          </div>
        </div>
      )
}

export {Card2}
