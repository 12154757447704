/* eslint-disable react/jsx-no-target-blank */
import {KTSVG} from '../../../helpers'
import {AsideMenuItem} from './AsideMenuItem'
import {Link } from 'react-router-dom'

export function AsideMenuMain() {
  return (
    <>

      <AsideMenuItem
        to='/'
        icon='/media/icons/duotune/general/fox.svg'
        title='Davy Back Fight'
        fontIcon='bi-layers'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>A venir</span>
        </div>
      </div>

      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      <div className='menu-item'>
        <Link
          className='menu-link'
          to='/changelog'
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </Link>
      </div>
    </>
  )
}
