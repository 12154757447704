import {React, useState} from 'react'
import {initialData} from './config/datas'
import {Column} from './Column'
import { DragDropContext } from 'react-beautiful-dnd';

export function Images() {
    const [datas, setDatas] = useState(initialData)
    const onDragEnd = result =>{
        const {destination, source, draggableId} = result

        console.log(destination)
        console.log(source)
        console.log(draggableId)

        if(!destination){
            return
        }

        if(destination.droppableId === source.droppableId && destination.index === source.index){
            return
        }

        //si on bouge les éléments
        const column = datas.columns[source.droppableId]
        // recuperation des taches actuelles
        const newTasksIds = Array.from(column.taskIds)
        // on remplace les places dans le tableau
        newTasksIds.splice(source.index,1)
        newTasksIds.splice(destination.index,0, draggableId)
        // on crée une copine de la colonne de taches modifiées
        const newColumn = {
            ...column,
            taskIds:newTasksIds,
        }
        // on met à jour l'état
        const newState = {
            ...datas,
            columns:{
                ...datas.columns,
                [newColumn.id]:  newColumn,
            }
        }

        setDatas(newState)
        console.log(column)

        return
    }



    return (
        <div className="images">
            <DragDropContext onDragEnd={onDragEnd}>
                {datas.columnOrder.map((columnId) => {
                    const column = datas.columns[columnId];
                    const imagesID = datas.imagesKey[Math.floor(Math.random() * datas.imagesKey.length)];
                    const test = datas.images[imagesID]
                    // const images = 
                    column.taskIds.map((imageId) => datas.images[imagesID] );
                    //console.log(imagesID)
                    return(<Column key={column.id} images={test} column={column} />);
                })}
            </DragDropContext>
        </div>
    )
}
