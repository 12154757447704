import { useEffect } from "react";

function Tiktok() {

  useEffect(() => {
    window.location.href = "https://www.tiktok.com/@shand0ra_";
  }, []);

  return (
    null
  );
}


export {Tiktok}
