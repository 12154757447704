import { useEffect } from "react";

function Discord() {

  useEffect(() => {
    window.location.href = "https://discord.com/invite/FYzTtcySR9";
  }, []);

  return (
    null
  );
}


export {Discord}
