import  {FC} from 'react'

import {
  PlayerLobbyCard,
} from '../../../_metronic/partials/'
import {MasterLayout} from '../../../_metronic/layout/MasterLayout'
import './style.scss';
import { KTUtil } from '../../../_metronic/assets/ts';

const DashboardPage = () => (
  <>
      <div className={!KTUtil.isMobileDevice() ?'col-xxl-4': 'col-10'}>
          <PlayerLobbyCard/>
      </div> 
  </>
)

const DashboardWrapper: FC = () => {
  return (
    <>
        <MasterLayout>
            <DashboardPage />
        </MasterLayout>
    </>  
  )
}

export {DashboardWrapper}
