import React from 'react'
import {MenuItem} from './MenuItem'

export function MenuInner() {
  return (
    <>
      <MenuItem title="Twitch" to='/twitch'  icon='/media/logos/twitch-logo.svg' />
      <MenuItem title='Discord' to='/discord' icon='/media/logos/discord-logo.svg'  />
      <MenuItem title='Twitter' to='/twitter' icon='/media/logos/twitter-logo.svg'  />
      <MenuItem title='Tiktok' to='/tiktok' icon='/media/logos/tiktok-logo.svg' />
    </>
  )
}
