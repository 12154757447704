import {FC} from 'react'

const Error404: FC = () => {
  return (
    <>
      <h1 className='fw-bolder fs-4x text-gray-700 mb-10'>Oups !</h1>

      <div className='fw-bold fs-3 text-gray-400 mb-15'>
        La page que tu cherche ne semble pas fonctionner ! <br /> Tu peux retourner à l'accueil grâce à ce bouton
      </div>
    </>
  )
}

export {Error404}
