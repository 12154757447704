/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
//import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {MasterLayoutLobby} from '../../../_metronic/layout/MasterLayoutLobby'
import {KTUtil} from '../../../_metronic/assets/ts'
import {DragAndDropComponent} from '../../../_metronic/layout/components/DragAndDrop'

import {
  Timer,
} from '../../../_metronic/partials/'

const DragAndDrop: FC = ()=> {


  return (
    <>
        <MasterLayoutLobby>
        {/* begin::Row */}
        <div className='row mt-5 gy-4 g-xl-8 d-flex flex-row-fluid'>
            {!KTUtil.isMobileDevice() && (
                <div className="container d-flex flex-column">
                    <h1 className="d-flex flex-column-fluid justify-content-center mb-3" >
                        CLASSEZ CES DRAPEAUX
                    </h1>
                    <h1 className="d-flex flex-column-fluid justify-content-center my-2" >
                        <Timer />
                    </h1>
                </div>
            )}

            {!KTUtil.isMobileDevice() && (
                <div className="d-flex flex-column">
                    <div className="d-flex flex-row-fluid flex-column flex-center my-5" >
                        <DragAndDropComponent />
                    </div>
                </div>
                )}

            {/* begin::Aside mobile toggle */}
            {KTUtil.isMobileDevice() && (
                <div className="container d-flex flex-column">
                    <h1 className="d-flex flex-column-fluid justify-content-center mb-3" >
                        CLASSEZ CES DRAPEAUX
                    </h1>
                    <h1 className="d-flex flex-column-fluid justify-content-center my-2" >
                        <Timer />
                    </h1>
                </div>
            )}

            {KTUtil.isMobileDevice() && (
                <div className="d-flex flex-column">
                    <div className="d-flex flex-row-fluid flex-row flex-center mb-5" >
                        <DragAndDropComponent />
                    </div>
                </div>
            )}


        </div>
        {/* end::Row */}
        </MasterLayoutLobby>
    </>
  )
}

export {DragAndDrop}
